import React, { FC } from "react";
import tw, { styled } from "twin.macro";

import Layout from "@/components/Layout";
import Logo from "@/components/Logo";
import Wordmark from "@/components/Wordmark";

const Wrapper = styled(Layout)`
  ${tw`flex flex-col items-center justify-center`}
`;

const StyledLogo = styled(Logo)`
  ${tw`mb-2 w-24`}
`;

const StyledWordmark = styled(Wordmark)`
  ${tw`w-64`}
`;

const Inner = styled.div`
  ${tw`mt-8`}
`;

const Splash: FC = (props) => {
  return (
    <Wrapper>
      <StyledLogo />
      <StyledWordmark />

      {props.children && <Inner>{props.children}</Inner>}
    </Wrapper>
  );
};

export default Splash;
