import { PageProps } from "gatsby";
import React, { FC, useMemo } from "react";
import tw, { styled } from "twin.macro";

import Spinner from "@/components/Spinner";
import Splash from "@/components/Splash";

const Message = styled.div`
  ${tw`bg-white/10 px-8 py-4 rounded shadow-lg`}
`;

const Title = styled.div`
  ${tw`font-bold text-3xl text-center uppercase`}
`;

const Description = styled.div`
  ${tw`text-lg text-center text-neutral-400`}
`;

const StyledSpinner = styled(Spinner)`
  ${tw`w-10`}
`;

const CallbackPage: FC<PageProps> = ({ location }) => {
  const message = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const hashParams = new URLSearchParams(location.hash.substring(1));

    const error = searchParams.get("error");
    const description = searchParams.get("error_description");

    if (error) {
      return (
        <Message>
          <Title>Error</Title>
          <Description>{description ?? "Unexpected error occurred"}</Description>
        </Message>
      );
    }

    const accessToken = hashParams.has("access_token");

    if (accessToken) {
      return (
        <Message>
          <Title>Authenticated</Title>
          <Description>You can close this window</Description>
        </Message>
      );
    }

    return (
      <StyledSpinner />
    );
  }, [location]);

  return <Splash>{message}</Splash>;
};

export default CallbackPage;
